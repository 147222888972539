@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
a{
    cursor: pointer;
}
.content-wpr {
    height: 94vh;
    background-size: 104%;
    background-position: 50% 100%;
    background-image: url(./images/bg.png);
    background-repeat: no-repeat;

}
.content-main{
    padding: 0px 80px;

}
.content-header{
    padding: 10px 0;
}
.header-left{
    text-align: left;
}
.header-left .logo{
    width: 145px;
    margin-left: -25px;
    text-align: center;
}
.header-left p{
    font-size: 8px;
    color: #0F398C;
    font-weight: 800;
    text-align: center;
}
.header-left img{
    width: 140px;
    margin-top: 5px;
}

.header-right{
    text-align: right;
    margin-right: 20px;
}

.header-right{
    margin: 20px auto !important;
}
.header-right a{
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
}

.header-right button{
    background-color: #1E61AD;
    padding: 8.3px 30px;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15.5px;
    margin-left: 20px;
}
.header-right button:hover{
    background-color: #2e7dd7;
    color: #fff;
}

.content-body{
    text-align: left;
    /* margin-left: 25px; */
}

.content-body h1{
    font: normal normal bold 22px/22px Gruppo;
    -webkit-text-stroke: 1px black;
}

.content-body p{
    font: normal normal bold 16px/22px Gruppo;
    color: #1E61AD;
    -webkit-text-stroke: 0.1px;
    /* padding-right: 40px; */

}
.content-body p b{
    -webkit-text-stroke: 0.5px;
}

.modal-dialog{
    margin: 0px !important;
    float: right;
    max-width: 500px;
    position: fixed !important;
    right: 0;
}

.modal-body .content-body{
    margin-left: 0px;
}
.modal-content{
    height: 100vh;
    border-radius: 0 !important;

}

.btn-close{
    --bs-btn-close-color: red !important;
}
.img-1{
    width: 40px;
    position: absolute;
    left: 15px;
    /* bottom: 200px; */
}
.img-2{
    width: 45px;
    position: absolute;
    right: 40px;
    bottom: 180px;
}

.img-3{
    position: absolute;
    bottom: -160px;
    right: -105px;
    width: 350px;
}

.img-4{
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 200px;
}

.social-links ul{
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;

}

.social-links p{
    margin-top: 25px;
    text-align: center;
    padding-right: 0;
    margin-bottom: 10px;
}

.contact-wpr{
    display: flex;
}

.contact-wpr div{
    flex-basis: 50%;
    margin: 0px 5px;
    gap: 0px;
    padding: 20px 10px;
    background-color: #1e61ad;
    color: #fff;
    margin-top: 30px;
}
.contact-wpr div p{
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    padding-right: 0;
}

.contact-wpr span{
    /* border: 1px solid;
    background: #fff;
    color: #1e61ad;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-top: -30px; */
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
}
.contact-wpr span b{
    margin-left: 6px;
    font-size: 13px;
    font-weight: 600;
}
.social-links ul li a{
    list-style: none;
    background: #fff;
    color: #1e61ad;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-radius: 50%; */
    margin: 0px 7px;
    border: 1px solid #1e61ad;
    text-decoration: none;
}

.content-footer{
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    font-size: 14px;
}

.content-footer p{
    margin-bottom: 10px;
}

.content-body-col{
    position: relative;
}
.bg-img-1{
    position: absolute;
    left: -40px;
    width: 40px;
    bottom: 40px;
}

.bg-img-2{
    position: absolute;
    right: 85px;
    width: 130px;
    bottom: -40px
}

.bg-img-3{
    position: absolute;
    right: -80px;
    width: 55px;
    bottom: 80px;
}
@media (max-width: 1399px) and (min-width: 1299px){
    .content-body p {
        font: normal normal bold 14px/22px Gruppo;
    }
    .header-right button, .header-right a{
        font-size: 13px;
    }
}
@media (max-width: 1300px) and (min-width: 786px){
    .content-body p {
        font: normal normal bold 14px/22px Gruppo;
    }
    .header-right button, .header-right a{
        font-size: 14px;
    }
    .content-wpr {
        height: 94vh;
        background-size: 100%;
    }
    .content-footer p {
        margin-bottom: 8px;
    }
}
@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1300px !important;
    }
}
@media (max-width: 1250px) {
    .header-right a {
        font-size: 14px;
    }

    .header-right button{
        font-size: 12px;
    }
}

@media (max-width: 1200px){
    .bg-img-3 {
        position: absolute;
        right: -35px;
        width: 46px;
        bottom: 93px;
    }
}
@media (max-width: 1100px){
    .img-2 {
        width: 40px;
    }
    .bg-img-3 {
        position: absolute;
        right: -38px;
        width: 46px;
        bottom: 93px;
    }
    .content-footer p {
        margin-bottom: 10px;
        font-size: 12px;
    }
}
@media (max-width: 768px){
    .header-right a {
        padding: 20px;
    }
    .header-left img {
        width: 100px;
        margin-top: 5px;
    }

    .content-header {
        padding: 0;
    }

    .header-right a {
        font-size: 12px;
        padding-right: 0px;
    }
    .header-right {
        margin: 5px auto !important;
    }
    .header-right button, .header-right button:hover {
        background-color: #1E61AD;
        padding: 6.3px 15px;
        border-radius: 30px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 9.5px;
        margin-left: 20px;
        margin-right: 0;
        margin-top: 9px;
    }
    .content-wpr {
        position: fixed;
        height: 100vh;
        background-size: 108%;
        background-position: 50% 92%;
    }
    .content-main{
        padding: 0px 20px;
    }
    .bg-img-1 {
        position: absolute;
        left: 10px;
        width: 30px;
        bottom: -60px;
    }
    .bg-img-2 {
        position: absolute;
        right: 92px;
        width: 100px;
        bottom: -20px;
    }

    .bg-img-3 {
        position: absolute;
        right: 0px;
        width: 35px;
        bottom: 0px;
    }
    .content-body {
        text-align: left;
        margin-top: 15px;
    }
    .header-left .logo {
        width: 115px;
        text-align: center;
        margin-left: -10px;
    }
    .modal-dialog {
        position: fixed !important;
        float: right;
        min-width: 100%;
    }
    .contact-wpr div {
        margin-top: 10px;
    }
    .content-footer p {
        font-size: 12px;
    }
    .img-2 {
        width: 34px;
        position: absolute;
        right: 10px;
        bottom: 135px;
    }
    .img-3 {
        position: absolute;
        bottom: -54px;
        right: -10px;
        width: 170px;
    }

    .img-4 {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 120px;
    }

    .content-body p {
        font: normal normal bold 15px/23px Gruppo;
        color: #1E61AD;
        -webkit-text-stroke: 0.1px;
    }

    .content-body h1 {
        font: normal normal bold 18px/22px Gruppo;
        -webkit-text-stroke: 1px black;
    }
    .modal-content {
        height: 100vh;
    }
    .modal-header{
        padding: 10px !important;
    }
    .contact-wpr span {
        font-size: 14px;
    }
    .contact-wpr div p {
        font-size: 18px;
    }
    .content-footer{
        background: #fff;
        padding-top: 10px;
    }
}